import { create } from 'zustand'

const useRoasStore = create((set) => ({
  displayMode: 'percentage',
  dayMode: 'cumulative',
  weekFilter: null, // null means show all days
  setDisplayMode: (mode) => set({ displayMode: mode }),
  setDayMode: (mode) => set({ dayMode: mode }),
  setWeekFilter: (day) => set({ weekFilter: day }),
}))

export { useRoasStore }

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const SourceSelect = ({ value, onValueChange }) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select source" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Select Source</SelectLabel>
          <SelectItem value="all">All</SelectItem>
          <SelectItem value="ads">Ads</SelectItem>
          <SelectItem value="meta">Meta</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default SourceSelect

import React from 'react'

import { Button } from '@/components/ui/button'
import DropdownCountry, { useCountry } from '@/components/DropdownCountry'
import { DropdownPid2, DropdownPid3, usePid2 } from '@/components/DropdownPid'
import { MyDatePicker, useDatePicker } from '@/components/MyDatePicker'
import { blobFetcher } from '@/hooks'
import useSWRMutation from 'swr/mutation'
import DataTable from './DataTable'
import DropdownPackageName, { usePackageName } from './DropdownPackage'

const Home = () => {
  return (
    <>
      <div className="flex items-center gap-4 flex-wrap sm:gap-2 sm:py-2 xs:py-1 xs:gap-1">
        <DropdownPid2 />
        <DropdownPid3 />
        <DropdownPackageName />
        <DropdownCountry />
        <MyDatePicker />
        <DownloadButton />
      </div>
      <DataTable />
    </>
  )
}
const DownloadButton = () => {
  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)
  const pids = usePid2((state) => state.pids)

  const country = useCountry((state) => state.value)
  const packageName = usePackageName((state) => state.value)

  const { trigger, isMutating } = useSWRMutation(
    ['/reports/roi', { startDate, endDate, pid: pids, country, packageName }],
    blobFetcher,
  )

  const handleDownloadCsv = async () => {
    try {
      const blob = await trigger()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'roi_report.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error downloading CSV:', error)
    }
  }

  return (
    <Button onClick={handleDownloadCsv} variant="outline">
      Download csv{isMutating && 'ing...'}
    </Button>
  )
}

export default Home

import { Button } from '@/components/ui/button'
import { computeRead } from '@/hooks/auth'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { optimisticAtom, putFetcher } from '@/hooks'
import useSWRMutation from 'swr/mutation'
import { getRoles } from '@/constants/role'
import { useMemo } from 'react'

const SetReadable = () => {
  const { id } = useParams()
  const roles = useMemo(() => getRoles(), [])

  const { data: user } = useSWR([`/users/${id}/full`])
  if (!user) return null

  return (
    <div className="flex flex-wrap gap-4">
      {roles.map((p) => (
        <ToggleButton p={p} key={p.code} user={user} />
      ))}
    </div>
  )
}

const ToggleButton = ({ user, p }) => {
  const { code, name } = p
  const reads = user?.roles ?? []
  const { trigger } = useSWRMutation(`/users/${user?.id}`, putFetcher)

  const handleClick = () => {
    const value = computeRead(reads, code)
    const payload = { roles: value.split(',') }
    trigger(payload)
    optimisticAtom(`/users/${user?.id}/full`, payload)
  }

  const vari = reads.some((r) => r === code) ? 'default' : 'outline'

  return (
    <Button variant={vari} onClick={handleClick}>
      {name}
    </Button>
  )
}

export default SetReadable

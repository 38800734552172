import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import useSWR from 'swr'

import Tabs from '@/components/Tabs'

const TabItems = [
  { name: 'Home', to: '', end: true },
  { name: 'Revenue', to: 'revenue' },
  { name: 'Cost', to: 'cost' },
  { name: 'ROAS', to: 'roas' },
  { name: 'ER', to: 'pr' },
  { name: 'Roles', to: 'roles' },
]

const Index = () => {
  const { id: userId } = useParams()
  const { data: user } = useSWR([`/users/${userId}/full`])

  return (
    <div className="flex flex-col">
      <div>{JSON.stringify(user, null, 2)}</div>
      <Tabs datas={TabItems} />
      <Outlet />
    </div>
  )
}

export default Index

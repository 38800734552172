import React from 'react'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'

import RoiPCCost from '@/features/roi/ProductContrast/Cost'
import RoiPCLayout from '@/features/roi/ProductContrast/Layout'
import RoiPCProfit from '@/features/roi/ProductContrast/Profit'
import RoiPCRevenue from '@/features/roi/ProductContrast/Revenue'
import RoiHome from '@/features/roi/Home'
import RoiCountryDetail from '@/features/roi/Home/CountryContrast'
import RoiLayout from './features/roi/Layout'

import RoiWeekly from '@/features/roi/Weekly'
import RoiWeeklyDoc from '@/features/roi/WeeklyDoc'

import RoasLayout from '@/features/roas'
import CampsPage from '@/features/roas/camps'
import RoasDetail from '@/features/roas/Detail'

import AdminLayout from '@/features/admin'
import SetCostReadable from '@/features/admin/SetCostReadable'
import SetPrReadable from '@/features/admin/SetPrReadable'
import SetRevenueReadable from '@/features/admin/SetRevenueReadable'
import SetRoasReadable from '@/features/admin/SetRoasReadable'
import SetRoles from '@/features/admin/SetRole'
import UserLayout from '@/features/admin/UserLayout'
import AppleEvents from '@/features/apple/Events1'
import Event1Roadmap from '@/features/apple/Events1Roadmap'
import AppleEvents2 from '@/features/apple/Events2'
import Event2Roadmap from '@/features/apple/Events2Roadmap'
import AppleLayout from '@/features/apple/Layout'
import SubAtTimeline from '@/features/apple/SubAtTimeline'
import WhatHappenAfterSubAt from '@/features/apple/WhatHappenAfterSubAt'
import AppleRegression from '@/features/roi/AppleRegression'

import EarningRate from '@/features/er'

import Nav from '@/components/Nav'

import LoginPage from '@/features/auth/FeishuLogin'

import Page401 from '@/components/zthd/401'
import Page403 from '@/components/zthd/403'
import Page404 from '@/components/zthd/404'
import Page500 from '@/components/zthd/500'
import { useAuthStore } from '@/hooks'
import { useRole, useRoles } from './hooks/auth'
import { Role } from '@/constants/role'
const useWindowSize = () => {
  const [size, setSize] = React.useState([
    window.innerHeight,
    window.innerWidth,
  ])
  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerHeight, window.innerWidth])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

const Welcome = () => {
  const size = useWindowSize()
  return (
    <div>
      <h1>Welcome to monday. </h1>
      <p>
        {size[1]} x{size[0]}
      </p>
    </div>
  )
}

const App = () => {
  return (
    <BrowserRouter>
      <Nav />
      <div className="container p-1">
        <Routes>
          <Route path="/" element={<RequireAuthLayout />}>
            <Route index element={<Welcome />} />
            <Route path="roi" element={<RoiLayout />}>
              <Route index element={<RoiHome />} />
              <Route path="neku-regression" element={<AppleRegression />} />
              <Route
                path="cc/:pids/:date"
                element={<RoiCountryDetail />}
              ></Route>
              <Route path="pc" element={<RoiPCLayout />}>
                <Route index element={<RoiPCRevenue />} />
                <Route path="cost" element={<RoiPCCost />} />
                <Route path="profit" element={<RoiPCProfit />} />
              </Route>
              <Route path="weekly" element={<RoiWeekly />} />
              <Route path="weekdoc" element={<RoiWeeklyDoc />} />
            </Route>

            <Route path="apple" element={<AppleLayout />}>
              <Route path="" element={<AppleEvents />} />
              <Route path="events1" element={<AppleEvents />} />
              <Route
                path="events1/:eventAt/roadmap"
                element={<Event1Roadmap />}
              />
              <Route path="events2" element={<AppleEvents2 />} />
              <Route
                path="events2/:subAt/roadmap"
                element={<Event2Roadmap />}
              />
              <Route path="events3" element={<WhatHappenAfterSubAt />} />
              <Route path="subat-timeline" element={<SubAtTimeline />} />
            </Route>
            <Route path="roas" element={<RoasLayout />}>
              <Route index element={<CampsPage />} />
              <Route path=":id" element={<RoasDetail />} />
            </Route>
            <Route path="er" element={<EarningRate />} />
            <Route path="admin" element={<RequireAdminLayout />}>
              <Route path="users/:id" element={<UserLayout />}>
                <Route path="roas" element={<SetRoasReadable />} />
                <Route path="revenue" element={<SetRevenueReadable />} />
                <Route path="cost" element={<SetCostReadable />} />
                <Route path="pr" element={<SetPrReadable />} />
                <Route path="roles" element={<SetRoles />} />
              </Route>
            </Route>
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="401" element={<Page401 />} />
          <Route path="403" element={<Page403 />} />
          <Route path="500" element={<Page500 />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

const RequireAuthLayout = () => {
  const isLoggedIn = useAuthStore((state) => state.auth?.id)
  const location = useLocation()
  if (isLoggedIn) {
    return <Outlet />
  }
  return <Navigate to="/login" state={{ from: location }} replace />
}

const RequireAdminLayout = () => {
  const hasRole = useRoles([Role.Admin, Role.BuyerLeader, Role.RevenuerLeader])
  if (!hasRole) return <Navigate to="/403" />
  return <AdminLayout />
}

export default App

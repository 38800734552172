import { useMemo, useState } from 'react'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { getColumns } from './columns'
import { useRoasStore } from '@/store/roas'
import { getWeek } from '@/lib/date-utils'

const DailyTable = ({ roas }) => {
  const [sorting, setSorting] = useState([{ id: 'install_date', desc: true }])
  const { weekFilter } = useRoasStore()

  const columns = useMemo(() => {
    return getColumns(roas?.summary?.maxDay ?? 7)
  }, [roas?.summary?.maxDay])

  const data = useMemo(() => {
    const rawData = roas?.dailyData ?? []
    if (weekFilter === null) return rawData

    return rawData.filter((item) => {
      const [dayIndex] = getWeek(item.install_date)
      return dayIndex === weekFilter
    })
  }, [roas, weekFilter])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  return (
    <div className="rounded-md border">
      <DataTableWithBorder table={table} />
    </div>
  )
}

export default DailyTable

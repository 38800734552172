import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import SetComponent from './SetComponent'
import { useRole } from '@/hooks/auth'
import { Role } from '@/constants/role'

const UPDATE_KEY = 'mediaBuyReadable'

const SetCostReadable = () => {
  const { id } = useParams()
  const { data: user } = useSWR([`/users/${id}/full`])
  const isBuyerLeader = useRole(Role.BuyerLeader)
  if (!isBuyerLeader) return null
  return <SetComponent user={user} updateKey={UPDATE_KEY} />
}

export default SetCostReadable

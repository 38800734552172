export const Role = {
  Admin: 'admin',
  BuyerLeader: 'buyer_leader',
  RevenuerLeader: 'revenuer_leader',
  Buyer: 'buyer',
  Revenuer: 'revenuer',
}

export const getRoles = () => {
  return Object.entries(Role).map(([key, value]) => ({
    code: value,
    name: key,
  }))
}

import React from 'react'
import { create } from 'zustand'
import Selector from '@/components/zthd/Selector'
import { usePid2 } from '@/components/DropdownPid'

const apps = [
  { code: 'total', name: 'Total' },
  { code: 'wonderful', name: 'Wonderful' },
  { code: 'samoyed', name: 'Samoyed' },
  { code: 'worker', name: 'Worker' },
  { code: 'takoyaki', name: 'Takoyaki' },
  { code: 'sushicat', name: 'Sushi Cat' },
  { code: 'detectivedog', name: 'Detective Dog' },
  { code: 'tanuki', name: 'Tanuki' },
  { code: 'summercat', name: 'Summer Cat' },
  { code: 'springbunny', name: 'Spring Bunny' },
  { code: 'mamekoslife', name: 'Mameko’s Life' },
  { code: 'hamstercookie', name: 'hamstercookie' },
  { code: 'guineapig', name: 'guineapig' },
  { code: 'calicocatdiary', name: 'calicocatdiary' },
]

export const usePackageName = create((set) => ({
  value: '',
  update: (value) => set({ value }),
}))

export const CustomDropdown = () => {
  const pids = usePid2((state) => state.pids)
  const pid = pids.join(',')
  const value = usePackageName((state) => state.value)
  const update = usePackageName((state) => state.update)
  if (pid !== '14') return null
  return (
    <Selector
      datas={apps}
      showLabel
      labelName="Single App"
      value={value}
      update={update}
    />
  )
}

export default CustomDropdown

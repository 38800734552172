import React from 'react'
import { MoreHorizontal } from 'lucide-react'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import StatusCell from '@/components/roas/camps/status-cell'
import MyLink from '@/components/zthd/my-link'
import UserSelector from '@/components/user-selector'
import useSWRMutation from 'swr/mutation'
import { putFetcher } from '@/hooks'
import { useNavigate } from 'react-router-dom'

const Header = ({ children }) => (
  <div className="font-medium truncate">{children}</div>
)

export const idColumn = {
  accessorKey: 'id',
  header: () => <Header>ID</Header>,
  cell: ({ row }) => <div className="font-medium">{row.getValue('id')}</div>,
}

export const nameColumn = {
  accessorKey: 'name',
  header: () => <Header>Name</Header>,
  cell: ({ row }) => {
    const campaign = row.original
    return (
      <div className="flex flex-col">
        {/* {JSON.stringify(campaign)} */}
        <div className="font-medium">
          <MyLink target="_blank" to={`/roas/${campaign.id}`}>
            {campaign.name}
          </MyLink>
        </div>
        <div className="text-xs text-muted-foreground">ID: {campaign.id}</div>
      </div>
    )
  },
}

export const mediaSourceColumn = {
  accessorKey: 'mediaSource',
  header: () => <Header>Source</Header>,
  cell: ({ row }) => (
    <div className="font-medium">{row.getValue('mediaSource')}</div>
  ),
}

export const countryColumn = {
  accessorKey: 'country',
  header: () => <Header>Region</Header>,
}

export const ownerColumn = {
  accessorKey: 'owner',
  header: () => <Header>Owner</Header>,
  cell: ({ row }) => {
    return <OwnerCell row={row} />
  },
}

const OwnerCell = ({ row }) => {
  const navigate = useNavigate()
  const ownerInfo = row.original.ownerInfo
  const { trigger } = useSWRMutation(
    `/campaigns/${row.original.id}`,
    putFetcher,
    {
      onSuccess: () => {
        navigate(0)
      },
    },
  )
  const handleContinue = (user) => {
    trigger({ owner: user.id })
  }
  return (
    <div className="flex items-center gap-2">
      <UserSelector selectedUser={ownerInfo} onContinue={handleContinue} />
      <Avatar className="h-6 w-6">
        <AvatarImage src={ownerInfo?.avatarThumb} />
        <AvatarFallback>{ownerInfo?.name?.[0] || '?'}</AvatarFallback>
      </Avatar>
      <span>{ownerInfo?.name}</span>
    </div>
  )
}

export const pidColumn = {
  accessorKey: 'pid',
  header: () => <Header>PID</Header>,
}

export const statusColumn = {
  accessorKey: 'state',
  header: () => <Header>Status</Header>,
  cell: StatusCell,
}

export const idxColumn = {
  accessorKey: 'idx',
  header: () => <Header>#</Header>,
  cell: ({ row }) => (
    <div className="text-muted-foreground">{row.index + 1}</div>
  ),
}

export const createActionsColumn = (menuItems) => ({
  id: 'actions',
  header: () => <Header>Actions</Header>,
  cell: ({ row }) => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          {React.Children.map(menuItems(row), (item, index) =>
            React.cloneElement(item, { key: `action-${index}` }),
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    )
  },
})

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { addDays, format } from 'date-fns'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import { Card } from '@/components/ui/card'
import { DateRangePicker } from '@/components/ui/date-range-picker'
import WeekSelect from '@/components/week-select'
import DailyTable from './daily-table'
import DailyChart from './daily-chart'
import Day0Chart from './day0-chart'
import { useRoasStore } from '@/store/roas'

const CampInfo = ({ id }) => {
  const { data: camp } = useSWR([`/campaigns/${id}`])

  if (!camp) return null

  return (
    <h3 className="text-lg font-medium flex gap-4">
      <span>{camp.name}</span>
      <span>{camp.country}</span>
      <span>{camp.pid}</span>
    </h3>
  )
}

const Detail = () => {
  const { id } = useParams()
  const [date, setDate] = useState({
    from: addDays(new Date(), -30),
    to: new Date(),
  })
  const { weekFilter, setWeekFilter } = useRoasStore()

  const params = {
    startDate: format(date.from, 'yyyy-MM-dd'),
    endDate: format(date.to, 'yyyy-MM-dd'),
  }
  const { data: roas } = useSWR([`/troas/${id}`, params])

  return (
    <div className="space-y-4 py-4">
      <CampInfo id={id} />
      <Card className="p-4">
        <div className="flex items-center justify-between"></div>
        {roas?.summary && (
          <div className="grid grid-cols-3 gap-4">
            <div>
              <div className="text-sm text-muted-foreground">Total Cost</div>
              <div className="text-2xl font-bold">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(roas.summary.totalCost)}
              </div>
            </div>
            <div>
              <div className="text-sm text-muted-foreground">Total Revenue</div>
              <div className="text-2xl font-bold">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(roas.summary.totalRevenue)}
              </div>
            </div>
            <div>
              <div className="text-sm text-muted-foreground">Total ROAS</div>
              <div className="text-2xl font-bold">
                {`${(
                  (roas.summary.totalRevenue / roas.summary.totalCost) *
                  100
                ).toFixed(2)}%`}
              </div>
            </div>
          </div>
        )}
      </Card>
      <Tabs defaultValue="table" className="w-full">
        <div className="flex items-center justify-between">
          <TabsList className="grid w-[400px] grid-cols-3">
            <TabsTrigger value="table">Table</TabsTrigger>
            <TabsTrigger value="chart">Chart</TabsTrigger>
            <TabsTrigger value="day0">Day0</TabsTrigger>
          </TabsList>
          <div className="flex items-center gap-4">
            <DateRangePicker date={date} setDate={setDate} />
            <WeekSelect value={weekFilter} onChange={setWeekFilter} />
            <Toggles />
          </div>
        </div>
        <TabsContent value="table" className="w-full">
          <DailyTable roas={roas} />
        </TabsContent>
        <TabsContent value="chart" className="w-full">
          <DailyChart roas={roas} />
        </TabsContent>
        <TabsContent value="day0" className="w-full">
          <Day0Chart roas={roas} />
        </TabsContent>
      </Tabs>
    </div>
  )
}

const Toggles = () => {
  const { displayMode, dayMode, setDisplayMode, setDayMode } = useRoasStore()
  return (
    <>
      <Tabs value={displayMode} onValueChange={setDisplayMode}>
        <TabsList>
          <TabsTrigger value="amount">$</TabsTrigger>
          <TabsTrigger value="percentage">%</TabsTrigger>
        </TabsList>
      </Tabs>
      <Tabs value={dayMode} onValueChange={setDayMode}>
        <TabsList>
          <TabsTrigger value="daily">Daily</TabsTrigger>
          <TabsTrigger value="cumulative">Cumulative</TabsTrigger>
        </TabsList>
      </Tabs>
    </>
  )
}

export default Detail

export const campaignStatusMap = {
  0: {
    label: 'Active',
    variant: 'success',
  },
  1: {
    label: 'Pending',
    variant: 'warning',
  },
  2: {
    label: 'Stop',
    variant: 'destructive',
  },
}

export const getStatusConfig = (state) => {
  return (
    campaignStatusMap[state] || {
      label: 'Unknown',
      variant: 'secondary',
    }
  )
}

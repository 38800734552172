import { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import { cn } from '@/lib/utils'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useColumnFilters } from '@/components/data-table-selectors'
import useSWR from 'swr'
import { formatPercent } from '@/lib/format-utils'
import {
  costColumn,
  costsDetailColumn,
  cpiColumn,
  installsColumn,
  profitColumn,
  revenueColumn,
  revenueDetailColumn,
  rpiColumn,
} from './columns'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'cc')

// 合并并排序 revenue 和 cost 对象，并返回 country 的唯一值数组
const mergeAndSortAndGetCountries = (revenue, cost) => {
  // 转换 revenue 对象为数组
  const countriesArray = Object.keys(revenue || {}).map((country) => ({
    country,
    totalValue: revenue[country]?.total || 0,
  }))

  // 转换 cost 对象为数组
  const cArray = Object.keys(cost || {}).map((country) => ({
    country,
    totalValue: cost[country]?.total?.costs || 0,
  }))

  // 合并两个数组并按 totalValue 从大到小排序
  const mergedAndSorted = [...countriesArray, ...cArray].sort(
    (a, b) => b.totalValue - a.totalValue,
  )

  // 获取 country 的唯一值数组
  const uniqueCountries = Array.from(
    new Set(mergedAndSorted.map((item) => item.country)),
  )

  return uniqueCountries
}

const CountryTable = () => {
  const { pids, date, packageName } = useParams()

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const { columnFilters } = useColumnFilters()

  const { data: revenue } = useSWR([
    '/revenues/cc',
    { date, pid: pids, packageName },
  ])
  const { data: cost } = useSWR(['/costs/cc', { date, pid: pids, packageName }])
  const { data: ers } = useSWR(['/ers/cc', { date, pid: pids, packageName }])

  const { data: baselines } = useSWR(['/ers/baselines'])

  const data = useMemo(() => {
    const uniqueCountries = mergeAndSortAndGetCountries(revenue, cost)
    return uniqueCountries.map((country) => ({
      country,
      revenues: revenue?.[country],
      revenue: revenue?.[country]?.total,
      costs: cost?.[country],
      cost: cost?.[country]?.total?.costs,
      installs: cost?.[country]?.total.installs,
      er: ers?.[country],
      baseline: baselines?.[country]?.[pids],
    }))
  }, [revenue, cost, ers, pids, baselines])

  const columns = useMemo(() => {
    const rows = []
    rows.push(columnCountry)
    rows.push(installsColumn)
    rows.push(revenueColumn)
    rows.push(costColumn)
    rows.push(profitColumn)
    rows.push(erColumn)
    rows.push(cpiColumn)
    rows.push(rpiColumn)
    rows.push(costsDetailColumn)
    rows.push(revenueDetailColumn)
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: { columnFilters, columnVisibility },
  })

  return (
    <>
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
        <Title />
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

const Title = () => {
  const { pids, date } = useParams()
  return (
    <div className="flex text-lg font-bold">
      Country contrast: {pids} - {date}
    </div>
  )
}

const columnCountry = {
  id: 'country',
  header: 'Country',
  accessorFn: (row) => row.country,
  cell: (row) => row.getValue(),
}

const erColumn = {
  id: 'er',
  accessorFn: (row) => {
    const er = row.er
    const baseline = row.baseline
    return { er, baseline }
  },
  header: () => 'ER',
  cell: (row) => {
    const { er, baseline } = row.getValue()
    const formatted = formatPercent(er / 100)
    const formattedBaseline = formatPercent(baseline)
    return (
      <div className="flex flex-col text-right">
        <div
          className={cn(
            'font-bold',
            er > 0 && er > baseline * 100 ? 'text-primary' : 'text-destructive',
          )}
        >
          {formatted}
        </div>
        <div className="text-xs text-muted">{formattedBaseline}</div>
      </div>
    )
  },
  enableHiding: false,
}

export default CountryTable

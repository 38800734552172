import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { cn } from '@/lib/utils'
import { UpdateIcon } from '@radix-ui/react-icons'
import {
  DropdownCountry1,
  createCountryStore,
} from '@/components/DropdownCountry'
import MyDatePicker, { useDatePicker } from '@/components/MyDatePicker'
import React, { useMemo } from 'react'
import useSWR from 'swr'
import SkuDropdown, { createSkuIdStore } from './DropdownSku'

const CACHE_KEY = 'event3'

const useSkuId = createSkuIdStore('1634387596', CACHE_KEY)
const useCountry = createCountryStore('US', CACHE_KEY)

const coreEvents = [
  // { code: 'Start Introductory Offer', zh: '开始介绍优惠' },
  // {
  //   code: 'Paid Subscription from Introductory Offer',
  //   zh: '从介绍优惠转为付费订阅 +',
  //   cs: 'text-primary',
  // },
  // { code: 'Renew', zh: '续订 +', cs: 'text-primary' },

  // {
  //   code: 'Renewal from Billing Retry',
  //   zh: '从账单重试续订 +',
  //   cs: 'text-primary',
  // },
  // { code: 'Refund', zh: '退款 -', cs: 'text-red-500' },
  { code: 'Total', zh: '总', cs: 'text-primary' },
]

const getErrorTypeColor = (errorType) => {
  switch (errorType) {
    case '正常':
      return 'text-green-500'
    case '异常':
      return 'text-red-500'
    default:
      return 'text-yellow-500'
  }
}

const ModelMetrics = ({ metrics }) => {
  if (!metrics) return null

  const getTrendColor = (trend) => {
    switch (trend) {
      case '上升':
        return 'bg-green-500'
      case '下降':
        return 'bg-red-500'
      default:
        return 'bg-yellow-500'
    }
  }

  const getQualityColor = (quality) => {
    switch (quality) {
      case '优秀':
        return 'bg-green-500'
      case '良好':
        return 'bg-blue-500'
      case '一般':
        return 'bg-yellow-500'
      default:
        return 'bg-red-500'
    }
  }

  return (
    <Card className="mt-4">
      <CardContent className="pt-6">
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <div className="space-y-2">
            <div className="text-sm text-muted-foreground">趋势分析</div>
            <div className="flex items-center gap-2">
              <Badge
                variant="secondary"
                className={getTrendColor(metrics?.trend)}
              >
                {metrics.trend}
              </Badge>
              <span className="text-sm">
                斜率: {metrics?.slope?.toFixed(2)}
              </span>
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-sm text-muted-foreground">拟合质量</div>
            <div className="flex items-center gap-2">
              <Badge
                variant="secondary"
                className={getQualityColor(metrics.fitQuality)}
              >
                {metrics.fitQuality}
              </Badge>
              <span className="text-sm">
                R²: {(metrics.rSquared * 100)?.toFixed(1)}%
              </span>
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-sm text-muted-foreground">误差分析</div>
            <div className="text-sm">
              平均误差: {metrics.averageError?.toFixed(1)}%
              <br />
              标准误差: {metrics.standardError?.toFixed(1)}
            </div>
          </div>

          {metrics.warning && (
            <div className="space-y-2">
              <div className="text-sm text-muted-foreground">警告信息</div>
              <div className="text-sm text-red-500">{metrics.warning}</div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

const ModelMetadata = ({ metadata }) => {
  if (!metadata) return null

  return (
    <Card className="mt-4">
      <CardContent className="pt-6">
        <div className="text-sm font-medium mb-4">模型元数据</div>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <div className="space-y-2">
            <div className="text-sm text-muted-foreground">预测方法</div>
            <div className="text-sm">{metadata.method}</div>
          </div>

          <div className="space-y-2">
            <div className="text-sm text-muted-foreground">训练周期</div>
            <div className="text-sm">
              {metadata.trainingPeriods} 期
              <br />
              最后更新: 第 {metadata.lastUpdatePeriod} 期
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-sm text-muted-foreground">预测范围</div>
            <div className="text-sm">
              未来 {metadata.predictionPeriods} 期
              <br />
              数据质量: {metadata.dataQuality}
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-sm text-muted-foreground">模型调整</div>
            <div className="text-sm">
              {metadata?.adjustments?.map((adjustment, index) => (
                <div key={index}>{adjustment}</div>
              ))}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const Event3 = () => {
  const skuId = useSkuId((state) => state.value)
  const updateSkuId = useSkuId((state) => state.update)
  const country = useCountry((state) => state.value)
  const updateCountry = useCountry((state) => state.update)

  const { startDate, endDate } = useDatePicker()

  const { data, mutate, isValidating } = useSWR([
    '/apple/events3',
    { pid: 12, country, startDate, endDate, skuId },
  ])

  const periods = useMemo(() => {
    if (!data?.data || !data?.result?.predictions) return []

    // 合并原始数据和预测数据
    const mergedData = data.data.map((item) => {
      const analysis = data.result.historicalAnalysis?.find(
        (h) => h.periodNumber === item.periodNumber,
      )

      return {
        ...item,
        isPrediction: false,
        analysis: analysis
          ? {
              actualValue: analysis.actualValue,
              fittedValue: analysis.fittedValue,
              errorPercent: analysis.errorPercent,
              errorType: analysis.errorType,
            }
          : null,
      }
    })

    const predictionData = data.result.predictions.map((pred) => ({
      periodNumber: pred.periodNumber,
      prediction: pred,
      isPrediction: true,
    }))

    // 使用 Map 来去重，如果有重复的 periodNumber，保留原始数据
    const periodsMap = new Map()

    // 先添加预测数据
    predictionData.forEach((item) => {
      periodsMap.set(item.periodNumber, item)
    })
    // 再添加原始数据，如果已存在则合并数据
    mergedData.forEach((item) => {
      const existingItem = periodsMap.get(item.periodNumber)
      if (existingItem) {
        periodsMap.set(item.periodNumber, {
          ...item,
          ...existingItem,
        })
      } else {
        periodsMap.set(item.periodNumber, item)
      }
    })

    // 转换回数组并按 periodNumber 降序排序
    return Array.from(periodsMap.values()).sort(
      (a, b) => b.periodNumber - a.periodNumber,
    )
  }, [data])

  return (
    <>
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
        <DropdownCountry1 value={country} update={updateCountry} />
        <MyDatePicker />
        <SkuDropdown value={skuId} update={updateSkuId} />
        <Button onClick={() => mutate()} disabled={isValidating}>
          <UpdateIcon
            className={cn('w-4 h-4 mr-2', isValidating && 'animate-spin')}
          />
          Refresh
          {isValidating && 'ing...'}
        </Button>
      </div>
      <ModelMetadata metadata={data?.result?.metadata} />
      <ModelMetrics metrics={data?.result?.modelMetrics} />
      <Table className="w-auto">
        <TableHeader>
          <TableRow>
            <TableHead>周期</TableHead>
            <TableHead>日期区间</TableHead>
            <TableHead>实际值</TableHead>
            <TableHead>预测值</TableHead>
            <TableHead>可信度</TableHead>
            <TableHead>误差</TableHead>
            <TableHead>状态</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {periods?.map((item) => (
            <TableItem key={item.periodNumber} item={item} />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

const TableItem = ({ item }) => {
  const prediction = item?.prediction
  const analysis = item?.analysis

  return (
    <TableRow>
      <TableCell className="font-medium">
        {item.periodNumber}
        {prediction && <span className="text-xs text-muted-foreground">*</span>}
      </TableCell>
      <TableCell>
        {item.periodStart} - {item.periodEnd}
      </TableCell>
      <TableCell>{item?.Total?.total}</TableCell>
      <TableCell>
        {prediction?.predictedTotal}
        {analysis?.fittedValue}
      </TableCell>
      <TableCell>
        {prediction?.confidence && (
          <div className="text-xs text-muted-foreground">
            {prediction?.confidence?.low}-{prediction?.confidence?.high}
          </div>
        )}
      </TableCell>
      <TableCell>
        {analysis && analysis.errorPercent && (
          <div className="text-xs text-muted-foreground">
            {analysis.errorPercent > 0 ? '+' : ''}
            {analysis.errorPercent?.toFixed(1)}%
          </div>
        )}
      </TableCell>
      <TableCell>
        {prediction?.confidence && (
          <div className="text-xs text-muted-foreground">
            {prediction?.reliability}
          </div>
        )}
        {item.analysis && (
          <div className={cn('text-xs', getErrorTypeColor(analysis.errorType))}>
            {analysis.errorType}
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

export default Event3

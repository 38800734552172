import { useAuthStore } from '@/hooks'
import { Role } from '@/constants/role'

export const useRole = (role, strict) => {
  const userRoles = useAuthStore((state) => state.auth?.user?.roles || [])
  if (strict) return userRoles.includes(role)
  return userRoles?.includes(Role.Admin) || userRoles?.includes(role)
}

export const useRoles = (roles) => {
  const userRoles = useAuthStore((state) => state.auth?.user?.roles || [])
  const isAdmin = userRoles?.includes(Role.Admin)
  const isRole = roles.some((role) => userRoles.includes(role))
  return isAdmin || isRole
}

export const useReadableEr = (pid, country) => {
  const reads = useAuthStore(
    (state) => state.auth?.user?.prReadable?.split(',') || [],
  )
  return isReadable(reads, pid, country)
}

export const useReadableCost = (pid, country) => {
  const reads = useAuthStore(
    (state) => state.auth?.user?.mediaBuyReadable?.split(',') || [],
  )
  return isReadable(reads, pid, country)
}

export const useReadableRevenue = (pid, country) => {
  const reads = useAuthStore(
    (state) => state.auth?.user?.revenueReadable?.split(',') || [],
  )
  return isReadable(reads, pid, country)
}

export const isReadable = (reads, pid, country) => {
  return reads.some((r) => {
    const [p, c] = r.split('-')
    if (p === '*' && c === '*') return true
    if (p === '*') return c === country
    if (c === '*') return p === pid
    return p === pid && c === country
  })
}

export const computeRead = (reads, pcode) => {
  const isExists = reads.includes(pcode)
  let result

  if (isExists) {
    // 如果找到了，则从数组中移除
    result = reads.filter((item) => item !== pcode)
  } else {
    // 如果没找到，则直接复制 reads 数组
    result = [...reads]
    result.push(pcode)
  }
  // 将结果拼接成字符串
  return result.filter((i) => i !== '').join(',')
}

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from '@/components/ui/table'
import { cn } from '@/lib/utils'
import { flexRender } from '@tanstack/react-table'
import { ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react'

const DataTableWithBorder = ({
  table,
  onHeadClick,
  onCellClick,
  className,
  footer,
}) => {
  return (
    <Table className={cn(className)}>
      <TableHeader className="">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const canSort =
                !header.isPlaceholder &&
                header.column.columnDef.enableSorting === true
              const sortDirection = canSort
                ? header.column.getIsSorted()
                : false

              return (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={
                    canSort
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                  className={cn(
                    'border-b border-black text-bold bg-muted',
                    canSort && 'cursor-pointer select-none',
                  )}
                >
                  <div className="flex items-center gap-1">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {canSort && (
                      <div className="w-4">
                        {sortDirection === 'asc' ? (
                          <ArrowUp className="h-4 w-4" />
                        ) : sortDirection === 'desc' ? (
                          <ArrowDown className="h-4 w-4" />
                        ) : (
                          <ArrowUpDown className="h-4 w-4" />
                        )}
                      </div>
                    )}
                  </div>
                </TableHead>
              )
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row, i) => (
            <TableRow
              key={row.id}
              className={cn(
                'data-[state=selected]:bg-yellow-100 hover:bg-yellow-100',
                i % 2 === 0 ? 'bg-gray-200' : 'bg-white',
              )}
              data-state={row.getIsSelected() && 'selected'}
              onClick={() => row.toggleSelected()}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  className="border border-slate-300 text-base"
                  onClick={() => onCellClick && onCellClick(cell)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={table.getAllColumns().length}
              className="h-24 text-center "
            >
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {footer && (
        <TableFooter className="sticky bottom-0 bg-background z-10">
          <TableRow>{footer}</TableRow>
        </TableFooter>
      )}
    </Table>
  )
}

export default DataTableWithBorder

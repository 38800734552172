import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import SetComponent from './SetComponent'
import { useRole } from '@/hooks/auth'
import { Role } from '@/constants/role'

const UPDATE_KEY = 'revenueReadable'

const SetReadable = () => {
  const { id } = useParams('')
  const { data: user } = useSWR([`/users/${id}/full`])
  const isRevenueLeader = useRole(Role.RevenueLeader)
  if (!isRevenueLeader) return null

  return <SetComponent user={user} updateKey={UPDATE_KEY} />
}

export default SetReadable

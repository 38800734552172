export function formatNumber(
  number,
  minFractionDigits = 0,
  maxFractionDigits = 0,
) {
  // 检查是否为 NaN
  if (
    Number.isNaN(number) ||
    number === undefined ||
    !Number.isFinite(number)
  ) {
    return '-'
  }

  // 格式化数字
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(number)

  return formattedNumber.replace(/,/g, '')
}

export function formatUSD(
  number,
  minFractionDigits = 2,
  maxFractionDigits = 2,
) {
  // 检查是否为 NaN
  if (
    Number.isNaN(number) ||
    number === undefined ||
    !Number.isFinite(number)
  ) {
    return '-'
  }

  // 格式化百分比
  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(number)
  return formattedNumber.replace(/,/g, '')
}

export function formatPercent(
  number,
  minFractionDigits = 0,
  maxFractionDigits = 2,
) {
  // 检查是否为 NaN
  if (
    Number.isNaN(number) ||
    number === undefined ||
    !Number.isFinite(number)
  ) {
    return '-'
  }

  // 使用 Intl.NumberFormat 格式化百分比
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(number)
}

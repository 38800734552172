import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { CheckIcon, PersonIcon } from '@radix-ui/react-icons'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { cn } from '@/lib/utils'
export function UserSelector({
  buttonText,
  hideUsers = [],
  selectedUser,
  onContinue,
  className,
}) {
  const { data: users } = useSWR(['/users'])
  const [localSelectedUser, setLocalSelectedUser] = useState(selectedUser)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setLocalSelectedUser(selectedUser)
    }
  }, [open, selectedUser])

  if (!users) return null

  const filteredUsers = users.filter(
    (user) => !hideUsers.some((hideUser) => hideUser.id === user.id),
  )

  const handleUserSelect = (user) => {
    setLocalSelectedUser(user)
  }

  const handleContinue = () => {
    onContinue(localSelectedUser)
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn('px-2 py-0 h-6 shadow-none', className)}
        >
          <PersonIcon className="h-4 w-4" />
          {buttonText}
        </Button>
      </DialogTrigger>
      <DialogContent
        className="gap-0 p-0 outline-none"
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeader className="px-4 pb-4 pt-5">
          <DialogTitle>Select a user</DialogTitle>
          <DialogDescription>
            Choose a user to add to this thread.
          </DialogDescription>
        </DialogHeader>
        <Command className="overflow-hidden rounded-t-none border-t bg-transparent">
          <CommandInput placeholder="Search user..." />
          <CommandList>
            <CommandEmpty>No users found.</CommandEmpty>
            <CommandGroup className="p-2">
              {filteredUsers.map((user) => (
                <CommandItem
                  key={user.email}
                  className="flex items-center px-2"
                  onSelect={() => handleUserSelect(user)}
                >
                  <Avatar>
                    <AvatarImage src={user.avatarThumb} alt="Image" />
                    <AvatarFallback>{user.name[0]}</AvatarFallback>
                  </Avatar>
                  <div className="ml-2">
                    <p className="text-sm font-medium leading-none">
                      {user.name}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {user.email}
                    </p>
                  </div>
                  {localSelectedUser && localSelectedUser.id === user.id ? (
                    <CheckIcon className="ml-auto flex h-5 w-5 text-primary" />
                  ) : null}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
        <DialogFooter className="flex items-center border-t p-4 sm:justify-between">
          {localSelectedUser ? (
            <div className="flex items-center">
              <Avatar className="mr-2">
                <AvatarImage src={localSelectedUser.avatarThumb} />
                <AvatarFallback>{localSelectedUser.name[0]}</AvatarFallback>
              </Avatar>
              <p className="text-sm font-medium">{localSelectedUser.name}</p>
            </div>
          ) : (
            <p className="text-sm text-muted-foreground">
              Select a user to add to this thread.
            </p>
          )}
          <Button onClick={handleContinue} disabled={!localSelectedUser}>
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default UserSelector

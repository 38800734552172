import { cn } from '@/lib/utils'
import useSWR from 'swr'
import { formatUSD } from '@/lib/format-utils'

export const ColumnCost = {
  id: 'cost',
  header: 'Cost',
  cell: ({ row }) => {
    const data = row.original
    return <div>{formatUSD(data.cost || 0, 0, 0)}</div>
  },
}

export const ColumnIAA = {
  id: 'iaa',
  header: 'IAA',
  cell: ({ row }) => {
    const data = row.original
    return (
      <div>
        {formatUSD(data.revenues?.total - data.revenues?.apple || 0, 0, 0)}
      </div>
    )
  },
}
const iapColumn = (duration) => ({
  id: `iap-${duration}`,
  header: `IAP-day${duration}`,
  cell: ({ row }) => {
    return <RenderIAP row={row} duration={duration} />
  },
})

const RenderIAP = ({ row, duration }) => {
  const rowData = row.original
  const country = rowData.country
  const dateStr = rowData.date.toString()
  const year = dateStr.substring(0, 4)
  const month = dateStr.substring(4, 6)
  const day = dateStr.substring(6, 8)
  const startDate = new Date(`${year}-${month}-${day}`)

  const endDate = new Date(startDate)
  endDate.setDate(startDate.getDate() + duration)

  const overToday = endDate > new Date()

  const eventEnd = parseInt(
    endDate.getFullYear().toString() +
      (endDate.getMonth() + 1).toString().padStart(2, '0') +
      endDate.getDate().toString().padStart(2, '0'),
  )

  const params = {
    subAt: rowData.date,
    eventEnd,
    country,
  }

  const { data: iapData } = useSWR(
    overToday || country === 'total' ? null : [`/revenues/reg/iap`, params],
  )
  if (overToday) {
    return <div>-</div>
  }

  if (!iapData) return <div>-</div>

  const r =
    rowData.revenues?.total - rowData.revenues?.apple - rowData.cost + iapData

  return (
    <div className={cn(r > 0 && 'text-green-500', r < 0 && 'text-red-500')}>
      {formatUSD(iapData || 0, 0, 0)}
      <br />
      <small>{formatUSD(r, 0, 0)}</small>
    </div>
  )
}

export const ColumnIAP = {
  id: 'iap',
  header: 'IAP',
  columns: [
    ...[3, 10, 17, 24, 31, 38, 45, 52, 59, 66].map((day) => iapColumn(day)),
  ],
}

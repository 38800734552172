import dayjs from 'dayjs'
import { getWeekRange } from '@/lib/date-utils'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useColumnFilters } from '@/components/data-table-selectors'
import DropdownCountry, { useCountry } from '@/components/DropdownCountry'
import { DropdownPid2, usePid2 } from '@/components/DropdownPid'
import DropdownWeekStart, { useWSStore } from '@/components/DropdownWeek'
import { MyDatePicker, useDatePicker } from '@/components/MyDatePicker'
import {
  useReadableCost,
  useReadableEr,
  useReadableRevenue,
} from '@/hooks/auth'
import useSWR from 'swr'
import { create, useStore as useZustandStore } from 'zustand'
import {
  columnCPI,
  columnCosts,
  columnDate,
  columnEr,
  columnInstalls,
  columnProfit,
  columnRevenue,
} from './columns'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'weekly')

const WeekonWeek = () => {
  return (
    <>
      <Form />
      <RenderTable />
    </>
  )
}

const Form = () => {
  return (
    <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap xs:gap-1 xs:py-1">
      <DropdownPid2 />
      <DropdownCountry />
      <MyDatePicker />
      <DropdownWeekStart />
    </div>
  )
}

const RenderTable = () => {
  const pids = usePid2((state) => state.pids)
  const country = useCountry((state) => state.value)

  const pid = pids.join(',')

  const readableCost = useReadableCost(pid, country)
  const readableRevenue = useReadableRevenue(pid, country)
  const readableEr = useReadableEr(pid, country)

  const { data: baselines } = useSWR([readableEr ? '/ers/baselines' : null])
  const erBaseLine = baselines?.[country]?.[pid] ?? 0.35

  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)

  const ws = useZustandStore(useWSStore, (state) => state.value)

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const columnFilters = useColumnFilters((state) => state.columnFilters)

  const { data: ers } = useSWR([
    '/ers/weekly',
    { pid, startDate, endDate, country, ws },
  ])

  const { data: revenues, mutate: mutateR } = useSWR([
    readableRevenue ? '/revenues/weekly' : null,
    { pid, startDate, endDate, country, ws },
  ])

  const { data: costs, mutate: mutateC } = useSWR([
    readableCost ? '/costs/weekly' : null,
    { pid, startDate, endDate, country, ws },
  ])

  const data = useMemo(() => {
    const weeks = getWeekRange(dayjs(startDate), ws)
    return weeks.map((week, idx) => ({
      week,
      preWeek: weeks.find((w) => w.id === weeks[idx + 1]?.id),
      costs: costs?.find((c) => c.id === week.id),
      preCosts: costs?.find((c) => c.id === weeks[idx + 1]?.id),
      revenues: revenues?.find((r) => r.id === week.id),
      preRevenues: revenues?.find((r) => r.id === weeks[idx + 1]?.id),
      er: ers?.[week.id],
      preEr: ers?.[weeks[idx + 1]?.id],
    }))
  }, [startDate, ws, costs, revenues, ers])

  const columns = useMemo(() => {
    const rows = []
    rows.push(columnDate)
    if (readableCost) {
      rows.push(columnInstalls)
    }
    if (readableRevenue) {
      rows.push(columnRevenue)
    }
    if (readableCost) {
      rows.push(columnCosts)
    }
    if (readableRevenue && readableCost) {
      rows.push(columnProfit)
    }
    rows.push(columnEr(erBaseLine))

    if (readableCost) {
      rows.push(columnCPI)
    }
    return rows
  }, [readableRevenue, readableCost, erBaseLine])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: {
      columnFilters,
      columnVisibility,
    },
  })

  const refresh = () => [mutateR(), mutateC()]

  return (
    <>
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
        <ToggleShowLine num={1} text="weekly sum" />
        <ToggleShowLine num={2} text="daily avg" />
        <ToggleShowLine num={3} text="weekly growth" />
        <Button onClick={refresh}>refresh</Button>
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

export const useShowDataStore = create((set) => ({
  line1: true,
  line2: true,
  line3: true,
  toggleLine: (num) =>
    set((state) => ({ [`line${num}`]: !state[`line${num}`] })),
}))

const ToggleShowLine = ({ num, text }) => {
  const value = useShowDataStore((state) => state?.[`line${num}`])
  const toggleLine = useShowDataStore((state) => state.toggleLine)

  const handleChange = () => {
    toggleLine(num)
  }

  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`line${num}`}
        checked={value}
        onCheckedChange={handleChange}
      />
      <label
        htmlFor="terms"
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {text}
      </label>
    </div>
  )
}

export default WeekonWeek

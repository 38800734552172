import { useEffect, useMemo, useState } from 'react'
import { DropdownCountry1 } from '@/components/DropdownCountry'
import useSWR from 'swr'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import { columns } from '@/components/roas/camps/data-table-columns'
import { useUrlParamsStore } from '@/stores/url-params-store'
import SourceSelect from '@/components/roas/source-select'
import AppSelect from '@/components/zthd/app-select'
import StatusSelect from '@/components/roas/camps/status-select'
import UserSelect from '@/components/monday/user-select'
import { useAuthStore } from '@/hooks'
import Loader from '@/components/zthd/Loader'

const CampsPage = () => {
  const params = useUrlParamsStore((state) => state.params)
  const setParam = useUrlParamsStore((state) => state.setParam)
  const setParams = useUrlParamsStore((state) => state.setParams)
  const removeParam = useUrlParamsStore((state) => state.removeParam)
  const id = useAuthStore((state) => state.auth?.user?.id)

  const [sorting, setSorting] = useState([])

  // 更新 URL 参数的函数
  const updateSearchParams = (key, value) => {
    if (value === 'total' || value === 'all') {
      removeParam(key)
    } else {
      setParam(key, value)
    }
  }

  useEffect(() => {
    setParams({
      pid: '1',
      state: '0',
      owner: id ?? undefined,
    })
  }, [setParams, id])

  const { data, isLoading } = useSWR(['/campaigns', params])

  const camps = useMemo(() => data ?? [], [data])

  const table = useReactTable({
    data: camps,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  return (
    <div className="flex flex-col gap-4 py-4 sm:gap-2 sm:py-2">
      <div className="flex items-center gap-4 flex-wrap sm:gap-2 sm:py-2 xs:py-1 xs:gap-1">
        <AppSelect
          value={params.pid || ''}
          onValueChange={(value) => updateSearchParams('pid', value)}
        />
        <DropdownCountry1
          value={params.country || ''}
          update={(value) => updateSearchParams('country', value)}
        />
        <SourceSelect
          value={params.source || ''}
          onValueChange={(value) => updateSearchParams('mediaSource', value)}
        />
        <StatusSelect
          value={params.state || ''}
          onValueChange={(value) => updateSearchParams('state', value)}
        />
        <UserSelect
          value={params.owner || ''}
          onValueChange={(value) => updateSearchParams('owner', value)}
        />
      </div>
      {isLoading && <Loader>Loading...</Loader>}
      <DataTableWithBorder className="w-full" table={table} />
    </div>
  )
}

export default CampsPage

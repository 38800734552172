import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

// Utils
const parseUrlParams = () => {
  if (typeof window === 'undefined') return {}
  try {
    const searchParams = new URLSearchParams(window.location.search)
    return Object.fromEntries(searchParams)
  } catch (error) {
    console.error('Failed to parse URL params:', error)
    return {}
  }
}

const updateBrowserUrl = (params) => {
  try {
    const searchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        searchParams.set(key, value)
      }
    })

    const newUrl = `${window.location.pathname}${
      searchParams.toString() ? `?${searchParams.toString()}` : ''
    }`
    window.history.pushState({}, '', newUrl)
  } catch (error) {
    console.error('Failed to update browser URL:', error)
  }
}

// URL sync middleware
const urlSyncMiddleware = (config) => (set, get, api) =>
  config(
    (args) => {
      set(args)
      const state = get()
      updateBrowserUrl(state.params)
    },
    get,
    api,
  )

// Create store with middleware
export const useUrlParamsStore = create(
  devtools(
    urlSyncMiddleware((set, get) => ({
      // Store initial URL params
      params: typeof window !== 'undefined' ? parseUrlParams() : {},

      // Update single param
      setParam: (key, value) => {
        set((state) => {
          const newParams = { ...state.params }
          if (value === null || value === undefined || value === '') {
            delete newParams[key]
          } else {
            newParams[key] = value
          }
          return { params: newParams }
        })
      },

      // Update multiple params at once
      setParams: (newParams) => {
        set((state) => {
          const mergedParams = { ...state.params }
          Object.entries(newParams).forEach(([key, value]) => {
            if (value === null || value === undefined || value === '') {
              delete mergedParams[key]
            } else {
              mergedParams[key] = value
            }
          })
          return { params: mergedParams }
        })
      },

      // Remove a single param
      removeParam: (key) => {
        set((state) => {
          const newParams = { ...state.params }
          delete newParams[key]
          return { params: newParams }
        })
      },

      // Reset all params
      resetParams: () => {
        set({ params: {} })
      },
    })),
    {
      name: 'url-params-store',
    },
  ),
)

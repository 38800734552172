import React from 'react'
import { Outlet } from 'react-router-dom'
import Tabs from '@/components/Tabs'

import DropdownCountry from '@/components/DropdownCountry'
import { MyDatePicker } from '@/components/MyDatePicker'

const TabItems = [
  { name: 'Revenue', to: '', end: true },
  { name: 'Cost', to: 'cost' },
  { name: 'Profit', to: 'profit' },
]

function Index() {
  return (
    <div className="flex flex-col">
      <div className="flex gap-4 items-center flex-wrap xs:gap-1 sm:gap-2 md:gap-4 md:py-2">
        <DropdownCountry />
        <MyDatePicker />
      </div>
      <Tabs datas={TabItems} />
      <Outlet />
    </div>
  )
}

export default Index

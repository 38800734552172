export const Apps = [
  { code: '1', name: 'Text', group: 'sticker' },
  { code: '2', name: 'Personal', group: 'sticker' },
  { code: '3', name: 'HD', group: 'sticker' },
  { code: '6', name: '2021', group: 'sticker' },
  { code: '4', name: 'Anim', group: 'sticker' },
  { code: '7', name: 'TG', group: 'sticker' },
  { code: '5', name: 'Maker', group: 'sticker' },
  { code: '9', name: 'StickTok', group: 'sticker' },
  { code: '13', name: 'HD2023', group: 'sticker' },
  { code: '14', name: 'JPSingle', group: 'sticker' },
  { code: '15', name: 'Keyboard', group: 'sticker' },

  { code: '11', name: 'Neku-A', group: 'neku' },
  { code: '12', name: 'Neku-iOS', group: 'neku' },
  { code: '16', name: 'Neku(OC-A)', group: 'neku' },
]

export const StickerApps = Apps.filter((app) => app.group === 'sticker')
export const NekuApps = Apps.filter((app) => app.group === 'neku')
export const GameApps = Apps.filter((app) => app.group === 'game')
export const TotalsApp = [
  { code: 'total', name: 'All' },
  { code: 'total1', name: 'Sticker' },
  { code: 'total2', name: 'Neku' },
]

export const App0 = [
  { code: 'total', name: 'All' },
  ...StickerApps,
  ...NekuApps,
]
export const Apps1 = [...TotalsApp, ...Apps]

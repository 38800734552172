export const colorRules = [
  {
    day: 'day0',
    threshold: 60,
    lowThreshold: 40,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day1',
    threshold: 70,
    lowThreshold: 50,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day2',
    threshold: 70,
    lowThreshold: 50,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day3',
    threshold: 70,
    lowThreshold: 50,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day4',
    threshold: 80,
    lowThreshold: 60,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day5',
    threshold: 85,
    lowThreshold: 65,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day6',
    threshold: 90,
    lowThreshold: 70,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day7',
    threshold: 95,
    lowThreshold: 75,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day8',
    threshold: 100,
    lowThreshold: 80,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day9',
    threshold: 100,
    lowThreshold: 80,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  {
    day: 'day10',
    threshold: 100,
    lowThreshold: 80,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
  // Default rule for day 11+
  {
    day: 'default',
    threshold: 100,
    lowThreshold: 80,
    belowColor: 'text-red-600',
    lowColor: 'text-foreground',
    aboveColor: 'text-green-600',
  },
]

export const getColorByValue = (value, cost, dayIndex) => {
  if (!value || !cost) return ''

  const ratio = (value / cost) * 100 // Convert to percentage
  const rule =
    colorRules.find((r) => r.day === `day${dayIndex}`) ||
    colorRules.find((r) => r.day === 'default')

  if (ratio <= rule.lowThreshold) return rule.belowColor
  if (ratio >= rule.threshold) return rule.aboveColor
  return rule.lowColor
}

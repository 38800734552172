import { Apps, StickerApps, NekuApps, TotalsApp } from './app'

export const PGames = [
  { code: '22', name: 'jigsaw', group: 'game' },
  { code: '23', name: 'jigsaw-iOS', group: 'game' },
]

export const Products1 = [...TotalsApp, ...StickerApps, ...NekuApps]

// Keep the original Products export for backward compatibility
export const Products = [
  { code: 'total', name: 'Total' },
  ...StickerApps,
  ...NekuApps,
]

// Export the new Products with group information as AppProducts
export { Apps }

export const Countries = [
  { code: 'total', name: 'Total' },
  { code: 'BR', name: 'BR *' },
  { code: 'JP', name: 'JP *' },
  { code: 'DE', name: 'DE' },
  { code: 'US', name: 'US *' },
  { code: 'MX', name: 'MX *' },
  { code: 'TW', name: 'TW' },
  { code: 'IT', name: 'IT' },
  { code: 'KR', name: 'KR' },
  { code: 'ES', name: 'ES *' },
  { code: 'HK', name: 'HK' },
  { code: 'SA', name: 'SA' },
  { code: 'TH', name: 'TH' },
  { code: 'CA', name: 'CA' },
  { code: 'lt5', name: 'LT5' },
  { code: 'FR', name: 'FR' },
  { code: 'CH', name: 'CH' },
  { code: 'SG', name: 'SG' },
  { code: 'IL', name: 'IL' },
  { code: 'ZA', name: 'ZA' },
  { code: 'ID', name: 'ID *' },
  { code: 'AT', name: 'AT' },
  { code: 'AR', name: 'AR *' },
  { code: 'GB', name: 'GB' },
  { code: 'CO', name: 'CO *' },
  { code: 'NL', name: 'NL' },
  { code: 'MY', name: 'MY *' },
  { code: 'AE', name: 'AE' },
  { code: 'BE', name: 'BE' },
  { code: 'AU', name: 'AU' },
  { code: 'PT', name: 'PT' },
  { code: 'NG', name: 'NG' },
  { code: 'IN', name: 'IN' },
  { code: 'EG', name: 'EG' },
  { code: 'TR', name: 'TR' },
  { code: 'CL', name: 'CL' },
  { code: 'RO', name: 'RO' },
  { code: 'OM', name: 'OM' },
  { code: 'FI', name: 'FI' },
  { code: 'NO', name: 'NO' },
  { code: 'PL', name: 'PL' },
  { code: 'UY', name: 'UY' },
  { code: 'IE', name: 'IE' },
  { code: 'PK', name: 'PK' },
  { code: 'LU', name: 'LU' },
  { code: 'KE', name: 'KE' },
  { code: 'RU', name: 'RU' },
]

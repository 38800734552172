import React from 'react'
import { create, useStore as useZustandStore } from 'zustand'
import Selector from './zthd/Selector'

export const WSS = [
  { code: 0, name: 'None' },
  { code: 1, name: '周一' },
  { code: 2, name: '周二' },
  { code: 3, name: '周三' },
  { code: 4, name: '周四' },
  { code: 5, name: '周五' },
  { code: 6, name: '周六' },
  { code: 7, name: '周日' },
]
export const createStore = (init) =>
  create((set) => ({
    value: init,
    update: (value) => set({ value }),
  }))

export const useWSStore = createStore(3)

export const CustomDropdown = () => {
  const value = useZustandStore(useWSStore, (state) => state.value)
  const update = useZustandStore(useWSStore, (state) => state.update)
  return (
    <Selector
      datas={WSS}
      showLabel
      labelName="First Day of the Week"
      value={value}
      update={update}
    />
  )
}

export default CustomDropdown

import { Outlet } from 'react-router-dom'
import { useRole } from '@/hooks/auth'
import { Navigate } from 'react-router-dom'
import { useAuthStore } from '@/hooks'
import { Role } from '@/constants/role'
const Layout = () => {
  const isBuyer = useRole(Role.Buyer)
  const isXN = useAuthStore((state) => state.auth?.user?.id === '2426ca7f')
  if (isBuyer || isXN) {
    return <Outlet />
  }
  return <Navigate to="/403" />
}

export default Layout

import { Countries } from '@/comm'
import Selector from '@/components/zthd/Selector'
import React from 'react'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const createCountryStore = (init = 'total', key = 'global') =>
  create(
    persist((set) => ({ value: init, update: (value) => set({ value }) }), {
      name: `country-storage-${key}`,
      storage: createJSONStorage(() => localStorage),
    }),
  )

export const useCountry = createCountryStore('total')

const DropdownCountry = () => {
  const value = useCountry((state) => state.value)
  const update = useCountry((state) => state.update)
  return <DropdownCountry1 value={value} update={update} />
}

export const DropdownCountry1 = ({ value, update }) => {
  return (
    <Selector
      datas={Countries}
      showLabel
      labelName="Country"
      value={value}
      update={update}
    />
  )
}

export default DropdownCountry

import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'
import updateLocale from 'dayjs/plugin/updateLocale'
import minMax from 'dayjs/plugin/minMax'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)
dayjs.extend(updateLocale)
dayjs.extend(customParseFormat)
dayjs.extend(minMax)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

export const getDateRange = (start, end = dayjs()) => {
  const result = []
  for (
    let currentDate = dayjs(end);
    currentDate.isSameOrAfter(start, 'day');
    currentDate = currentDate.subtract(1, 'day')
  ) {
    result.push(Number(currentDate.format('YYYYMMDD')))
  }
  return result
}

export const getWeek = (data) => {
  const index = data ? dayjs(`${data}`, 'YYYYMMDD').day() : 8
  const mappings = ['日', '一', '二', '三', '四', '五', '六', '？？']
  return [index, mappings[index]]
}

export const getWeekRange = (openTime, weekStart = 5) => {
  const weeks = {}
  let startDate = openTime.day(weekStart)
  const currentDate = dayjs().subtract(1, 'day')

  const generateWeekId = (date) => {
    const year = date.year()
    const week = date.isoWeek()
    return year * 100 + week
  }

  const addDayToWeek = (weekId, date) => {
    const numberCurrentDate = Number(date.format('YYYYMMDD'))
    if (weeks[weekId]) {
      weeks[weekId].days.push(numberCurrentDate)
    } else {
      weeks[weekId] = { id: weekId, days: [numberCurrentDate] }
    }
  }

  while (startDate.isBefore(currentDate)) {
    const weekId = generateWeekId(startDate)
    for (let j = 0; j < 7; j++) {
      if (startDate.isSameOrAfter(currentDate)) {
        break
      }
      addDayToWeek(weekId, startDate)
      startDate = startDate.add(1, 'day')
    }
  }

  return Object.values(weeks).sort((a, b) => b.id - a.id)
}

import { mutate } from 'swr'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export const baseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://mondayserver-m3dlsop5cq-de.a.run.app'
    : 'http://localhost:3004'

export const useAuthStore = create(
  devtools((set) => ({
    auth: JSON.parse(localStorage.getItem('auth') || '{}'),
    setToken: (auth) => {
      localStorage.setItem('auth', JSON.stringify(auth))
      set({ auth })
    },
    removeToken: () => {
      localStorage.removeItem('auth')
      set({ auth: '{}' })
    },
  })),
)

const urlWrpper = async (endpoint, params = {}, options = {}) => {
  const xUserId = useAuthStore.getState().auth?.id

  const url = new URL(`${baseUrl}${endpoint}`)
  if (params) {
    const validParams = assembleParams(params)
    Object.keys(validParams).forEach((key) =>
      url.searchParams.append(key, validParams[key]),
    )
  }

  const headers = {
    'Content-Type': 'application/json',
    ...(xUserId && { 'x-user-id': xUserId }),
    ...options.headers, // Add custom headers
  }
  const response = await fetch(url, {
    ...options,
    headers,
  })

  if (!response.ok) {
    if (response.status === 401) {
      // Token might be expired, handle token refresh or redirect to login
      useAuthStore.getState().removeToken()
      // Optionally redirect to login page or refresh token
    }
    throw new Error('Failed to update data')
  }
  return response
}

export const tokenFetcher = async (endpoint, { arg }) => {
  const url = new URL(`${baseUrl}${endpoint}`)

  const headers = {
    'Content-Type': 'application/json',
  }
  const response = await fetch(url, {
    method: 'POST',
    headers,
    credentials: 'include', // 包括凭证信息
    body: JSON.stringify(arg),
  })
  if (!response.ok) {
    if (response.status === 401) {
      useAuthStore.getState().removeToken()
    }
    throw new Error(
      `HTTP error! Status: ${response.status}: ${response.statusText}`,
    )
  }
  return response.json()
}

export const delFetcher = async (endpoint, { arg }) => {
  const url = `${endpoint}${arg}`
  const response = await urlWrpper(url, null, {
    method: 'DELETE',
  })
  return response.json()
}

export const postFetcher = async (endpoint, { arg }, options = {}) => {
  const response = await urlWrpper(endpoint, null, {
    ...options,
    method: 'POST',
    body: JSON.stringify(arg),
  })
  return response.json()
}

export const putFetcher = async (endpoint, { arg }, options = {}) => {
  const response = await urlWrpper(endpoint, null, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(arg),
  })
  return response.json()
}

export const fetcher = async ([endpoint, params = {}, options = {}]) => {
  const response = await urlWrpper(endpoint, params, options)
  return response.json()
}

export const blobFetcher = async ([endpoint, params = {}, options = {}]) => {
  const response = await urlWrpper(endpoint, params, {
    ...options,
    headers: { 'Content-Type': 'text/csv', Accept: 'text/csv' },
  })
  return response.blob()
}

const assembleParams = (params) => {
  const validParams = {}

  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined && value !== '') {
      validParams[key] = value
    }
  }

  // 获取排序后的对象键值对数组
  const sortedEntries = Object.entries(validParams).sort(([keyA], [keyB]) =>
    keyA.localeCompare(keyB),
  )

  // 生成排序后的对象
  return Object.fromEntries(sortedEntries)
}

export const mutateRequest = (path) => {
  mutate((key) => Array.isArray(key) && key[0] === path, undefined, {
    revalidate: true,
  })
}

export const optimisticUser = (id, body) => {
  return optimisticAtom(`/users/${id}`, body)
}

export const optimisticAtom = (url, newData) => {
  mutate(
    (key) => Array.isArray(key) && key[0] === url,
    (atom) => ({ ...atom, ...newData }),
    {
      revalidate: false,
    },
  )
}

import { useParams } from 'react-router-dom'
import { computeRead } from '@/hooks/auth'
import useSWR, { mutate } from 'swr'

import useSWRMutation from 'swr/mutation'
import { optimisticAtom, putFetcher } from '@/hooks'

import { Button } from '@/components/ui/button'
import { Countries } from '@/comm'

const SetReadable = () => {
  const { id } = useParams('')
  const { data: user } = useSWR([`/users/${id}/full`])

  if (!user) return null

  return (
    <div className="flex flex-wrap gap-4">
      {Countries.map((e) => (
        <ToggleButton entity={e} key={e.code} user={user} />
      ))}
    </div>
  )
}

const ToggleButton = ({ user, entity }) => {
  const { code, name } = entity
  const reads = user?.camproiReadable?.split(',') ?? []
  const { trigger } = useSWRMutation(`/users/${user?.id}`, putFetcher)

  const handleClick = async () => {
    const value = computeRead(reads, code)
    const payload = { camproiReadable: value }
    trigger(payload)
    optimisticAtom(`/users/${user?.id}/full`, payload)
  }

  const vari = reads.some((r) => r === code) ? 'default' : 'outline'

  return (
    <Button variant={vari} onClick={handleClick}>
      {name}
    </Button>
  )
}

export default SetReadable

import { Products } from '@/comm'
import { useDatePicker } from '@/components/MyDatePicker'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { formatPercent, formatUSD } from '@/lib/format-utils'
import { getDateRange } from '@/lib/date-utils'

import { Button } from '@/components/ui/button'
import { createColumnVisibilityStore } from '@/components/zthd/data-table'
import { DataTableViewOptions } from '@/components/zthd/data-table-view-options'
import DataTableWithBorder from '@/components/zthd/data-table/data-table-with-border'
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  useColumnFilters,
  VSWeekSelector,
} from '@/components/data-table-selectors'
import { useCountry } from '@/components/DropdownCountry'
import useSWR from 'swr'
import Td2 from '../Home/Td2'
import { columnDate } from './columns'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'pcr')

const pidColumn = (code, name) => ({
  id: `${code}-${name}`,
  header: `${code}-${name}`,
  accessorFn: (row) => {
    const curr = row.datas?.[code]
    const total = row.datas?.total
    return { curr, total }
  },
  cell: (row) => {
    const { curr, total } = row.getValue()
    const formatted = formatUSD(curr, 0, 0)
    const per = formatPercent(curr / total)
    return <Td2 line2={per}>{formatted}</Td2>
  },
})

const Home = () => {
  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)
  const country = useCountry((state) => state.value)

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const columnFilters = useColumnFilters((state) => state.columnFilters)

  const { data: revenues, mutate } = useSWR([
    '/revenues/pc',
    { startDate, endDate, country },
  ])

  const data = useMemo(() => {
    const dateRange = getDateRange(dayjs(startDate), dayjs(endDate))
    return dateRange.map((date) => ({
      date,
      datas: revenues?.[date],
    }))
  }, [startDate, endDate, revenues])

  const columns = useMemo(() => {
    const ps = Products.filter(({ code }) => !['22', '23'].includes(code))
    const rows = []
    rows.push(columnDate)
    ps.forEach((p) => {
      const { code, name } = p
      rows.push(pidColumn(code, name))
    })
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableMultiRowSelection: true,
    state: {
      columnFilters,
      columnVisibility,
    },
  })

  return (
    <>
      <div className="flex items-center xs:gap-1 xs:py-1 sm:gap-2 sm:py-2 gap-4 py-4   flex-wrap">
        <VSWeekSelector />
        <Button variant="outline" onClick={mutate}>
          refresh
        </Button>
        <DataTableViewOptions table={table} />
      </div>
      <DataTableWithBorder table={table} />
    </>
  )
}

export default Home

import React, { useEffect } from 'react'

import {
  createCountryStore,
  DropdownCountry1,
} from '@/components/DropdownCountry'
import { usePid2 } from '@/components/DropdownPid'
import { MyDatePicker } from '@/components/MyDatePicker'
import DataTable from './DataTable'

export const useCountry = createCountryStore('US', 'apple-regression')

const AppleRegression = () => {
  const country = useCountry((state) => state.value)
  const updateCountry = useCountry((state) => state.update)
  const updateSingle = usePid2((state) => state.updateSingle)
  useEffect(() => {
    updateSingle(12)
  }, [updateSingle])

  return (
    <>
      <div className="flex items-center gap-4 flex-wrap sm:gap-2 sm:py-2 xs:py-1 xs:gap-1">
        <DropdownCountry1 value={country} update={updateCountry} />
        <MyDatePicker />
      </div>
      <DataTable />
    </>
  )
}

export default AppleRegression

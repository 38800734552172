import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts'
import { Card } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { cn } from '@/lib/utils'

const DailyChart = ({ roas }) => {
  const [visibleLines, setVisibleLines] = useState({})

  useEffect(() => {
    if (roas?.dailyData) {
      setVisibleLines(
        roas.dailyData.reduce((acc, row) => {
          acc[row.install_date] = true
          return acc
        }, {}),
      )
    }
  }, [roas?.dailyData])

  if (!roas?.dailyData) return null

  // Transform data for chart
  const dailyData = roas.dailyData
  const maxDay = roas.summary?.maxDay ?? 0

  // Create series data for each install date
  const series = dailyData.map((row) => {
    const data = []
    let cumulative = 0

    // Calculate cumulative values for each day
    for (let i = 0; i <= maxDay; i++) {
      const dayValue = row.days[`day${i}`]
      if (!dayValue) break
      cumulative += dayValue

      // Calculate ROAS percentage
      const roasPercentage = row.cost ? (cumulative / row.cost) * 100 : 0
      data.push({
        day: `Day${i}`,
        value: roasPercentage,
      })
    }

    return {
      name: row.install_date,
      data,
    }
  })

  // Prepare data for Recharts
  const chartData = []
  let maxValue = 0
  for (let i = 0; i <= maxDay; i++) {
    const point = {
      day: `Day${i}`,
    }
    series.forEach((s) => {
      if (visibleLines[s.name]) {
        const dataPoint = s.data.find((d) => d.day === `Day${i}`)
        if (dataPoint) {
          point[s.name] = dataPoint.value
          maxValue = Math.max(maxValue, dataPoint.value)
        }
      }
    })
    chartData.push(point)
  }

  // Calculate nice Y axis max value
  const yAxisMax = Math.ceil(maxValue / 50) * 50

  const toggleLine = (name) => {
    setVisibleLines((prev) => ({
      ...prev,
      [name]: !prev[name],
    }))
  }

  return (
    <Card className="p-4">
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={chartData}>
          <XAxis
            dataKey="day"
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
          />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            domain={[0, yAxisMax]}
            tickFormatter={(value) => `${value.toFixed(0)}%`}
          />
          <Tooltip
            formatter={(value) => `${value.toFixed(2)}%`}
            labelStyle={{ color: '#000' }}
            contentStyle={{ backgroundColor: '#fff', borderRadius: '6px' }}
          />
          <ReferenceLine
            y={100}
            stroke="#888888"
            strokeDasharray="3 3"
            label={{
              value: '100%',
              position: 'right',
              fontSize: 12,
              fill: '#888888',
            }}
          />
          {series.map((s, index) =>
            visibleLines[s.name] ? (
              <Line
                key={s.name}
                type="monotone"
                dataKey={s.name}
                stroke={`hsl(${index * 30}, 70%, 50%)`}
                strokeWidth={2}
                dot={false}
              />
            ) : null,
          )}
        </LineChart>
      </ResponsiveContainer>
      <div className="mt-4 flex flex-wrap gap-4">
        {series.map((s, index) => (
          <Button
            key={s.name}
            onClick={() => toggleLine(s.name)}
            variant="ghost"
            className={cn(
              'transition-all',
              visibleLines[s.name] ? 'opacity-100' : 'opacity-30',
            )}
            style={{
              backgroundColor: `hsl(${index * 30}, 70%, 95%)`,
              color: `hsl(${index * 30}, 70%, 50%)`,
            }}
          >
            {format(new Date(s.name), 'MM-dd')}
          </Button>
        ))}
      </div>
    </Card>
  )
}

export default DailyChart

import { Outlet } from 'react-router-dom'
import UserItemNav from './Users'

const Index = () => {
  return (
    <main className="flex flex-col">
      <div className="flex gap-4 py-4 sm:gap-2 sm:py-2">
        <h1 className="text-3xl font-semibold">Admin</h1>
      </div>
      <div className="flex flex-col md:flex-row gap-4 py-4 space-x-4 sm:gap-2 sm:py-2">
        <div className="lg:w-1/5">
          <UserItemNav />
        </div>
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </main>
  )
}

export default Index

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const WeekSelect = ({ value, onChange }) => {
  const days = ['日', '一', '二', '三', '四', '五', '六']

  const getDisplayValue = () => {
    if (value === null) return 'All Days'
    return `星期${days[value]}`
  }

  return (
    <Select
      value={value === null ? 'all' : value.toString()}
      onValueChange={(val) => onChange(val === 'all' ? null : Number(val))}
    >
      <SelectTrigger className="w-[120px]">
        <SelectValue>{getDisplayValue()}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">All Days</SelectItem>
        {days.map((day, index) => (
          <SelectItem key={index} value={index.toString()}>
            星期{day}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default WeekSelect

import Selector from '@/components/zthd/Selector'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
export const Skus = [
  { code: '1634387596', name: 'Year', alias: 'yearSub' },
  { code: '1634386893', name: 'Weekly', alias: 'weeklySub' },
  { code: '6738577188', name: 'Week_Last', alias: 'Week_Last' },
  { code: '6502536753', name: 'Month', alias: 'monthSub' },
  { code: '6738158046', name: 'Year_High', alias: 'yearHigh' },
]
export const createSkuIdStore = (init = '', key = '') =>
  create(
    persist(
      (set) => ({
        value: init,
        update: (value) => set({ value }),
      }),
      {
        name: `sku-id-storage-${key}`,
        storage: createJSONStorage(() => localStorage),
      },
    ),
  )

const DropdownSku = ({ value, update }) => {
  return (
    <Selector
      datas={Skus}
      update={update}
      value={value}
      labelName="Sku"
      showLabel
    />
  )
}
export default DropdownSku

import { cn } from '@/lib/utils'
import { formatNumber, formatPercent, formatUSD } from '@/lib/format-utils'
import { useShowDataStore } from '.'

export const Td3 = ({ children, avg, growth }) => {
  const formattedGrowth = formatPercent(growth)
  const { line1, line2, line3 } = useShowDataStore()
  return (
    <div className="flex flex-col text-right">
      {line1 && <div>{children}</div>}
      {line2 && (
        <div className={cn(line1 ? 'text-primary text-xs' : 'text-base')}>
          {avg}
        </div>
      )}
      {line3 && (
        <div
          className={cn(
            'text-xs',
            growth > 0 ? 'text-primary' : 'text-destructive',
          )}
        >
          {growth > 0 ? <>&uarr;</> : <>&darr;</>}
          {formattedGrowth}
        </div>
      )}
    </div>
  )
}

export const columnDate = {
  id: 'week',
  accessorFn: (row) => {
    return row.week
  },
  header: 'Week',
  cell: (row) => {
    const week = row.getValue()
    return (
      <div className="flex flex-col text-left">
        <div>
          {week.days[0]}~{week.days[week.days.length - 1]}
        </div>
        <div className="text-xs text-foreground/80">
          {week.id} ({week.days.length}days)
        </div>
      </div>
    )
  },
}

export const columnInstalls = {
  id: 'installs',
  accessorFn: (row) => {
    const costs = row.costs
    const preCosts = row.preCosts
    const installs = costs?.installs
    const preInstalls = preCosts?.installs

    const avg = installs / costs?.days.length

    const growth = (installs - preInstalls) / preInstalls

    return { installs, growth, avg }
  },
  header: 'Installs',
  cell: (row) => {
    const { installs, growth, avg } = row.getValue()
    const formatted = formatNumber(installs, 0, 0)
    const formattedAvg = formatNumber(avg, 0, 0)
    return (
      <Td3 avg={formattedAvg} growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnRevenue = {
  id: 'revenues',
  accessorFn: (row) => {
    const r = row.revenues
    const pre = row.preRevenues

    const avg = r?.total / r?.days.length
    const preAvg = pre?.total / pre?.days.length

    const growth = (avg - preAvg) / preAvg

    return { revenue: r?.total, growth, avg }
  },
  header: 'Revenues',
  cell: (row) => {
    const { revenue, growth, avg } = row.getValue()
    const formatted = formatUSD(revenue, 0, 0)
    const formattedAvg = formatUSD(avg, 0, 0)
    return (
      <Td3 avg={formattedAvg} growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnCosts = {
  id: 'costs',
  accessorFn: (row) => {
    const c = row.costs
    const preC = row.preCosts
    const costs = c?.costs
    const preCosts = preC?.costs

    const avg = costs / c?.days.length

    const growth = (costs - preCosts) / preCosts

    return { costs, growth, avg }
  },
  header: 'Costs',
  cell: (row) => {
    const { costs, growth, avg } = row.getValue()
    const formatted = formatUSD(costs, 0, 0)
    const formattedAvg = formatUSD(avg, 0, 0)
    return (
      <Td3 avg={formattedAvg} growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnProfit = {
  id: 'profit',
  accessorFn: (row) => {
    const c = row.costs
    const r = row.revenues
    const profit = r?.total - c?.costs

    const preC = row.preCosts
    const preR = row.preRevenues
    const preProfit = preR?.total - preC?.costs

    const avg = profit / c?.days.length
    const growth = (profit - preProfit) / preProfit

    return { profit, growth, avg }
  },
  header: 'Profit',
  cell: (row) => {
    const { profit, growth, avg } = row.getValue()
    const formatted = formatUSD(profit, 0, 0)
    const formattedAvg = formatUSD(avg, 0, 0)
    return (
      <Td3 avg={formattedAvg} growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

export const columnEr = (erBaseLine) => ({
  id: 'er',
  accessorFn: (row) => {
    const er = row.er / 100
    const preEr = row.preEr / 100
    const growth = (er - preEr) / preEr
    return { er, growth }
  },
  header: () => (
    <div>
      ER<span className="text-xs">({erBaseLine * 100})%</span>
    </div>
  ),
  cell: (row) => {
    const { er, growth } = row.getValue()
    const formatted = formatPercent(er)
    return (
      <Td3 avg="-" growth={growth}>
        {formatted}
      </Td3>
    )
  },
})

export const columnCPI = {
  id: 'cpi',
  accessorFn: (row) => {
    const c = row.costs
    const cpi = c?.costs / c?.installs

    const preC = row.preCosts
    const preCpi = preC?.costs / preC?.installs

    const growth = (cpi - preCpi) / preCpi

    return { cpi, growth }
  },
  header: 'CPI',
  cell: (row) => {
    const { cpi, growth } = row.getValue()
    const formatted = formatUSD(cpi, 4, 4)
    return (
      <Td3 avg="-" growth={growth}>
        {formatted}
      </Td3>
    )
  },
}

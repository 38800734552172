import { Button } from '@/components/ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { cn } from '@/lib/utils'
import { UpdateIcon } from '@radix-ui/react-icons'
import {
  DropdownCountry1,
  createCountryStore,
} from '@/components/DropdownCountry'
import MyDatePicker, { useDatePicker } from '@/components/MyDatePicker'
import React from 'react'
import useSWR from 'swr'
import SkuDropdown, { createSkuIdStore } from './DropdownSku'

const CACHE_KEY = 'event3'

const useSkuId = createSkuIdStore('1634387596', CACHE_KEY)
const useCountry = createCountryStore('US', CACHE_KEY)

const eventOrder = [
  { code: 'Start Introductory Offer', zh: '开始介绍优惠' },
  {
    code: 'Paid Subscription from Introductory Offer',
    zh: '从介绍优惠转为付费订阅 +',
    cs: 'text-primary',
  },
  { code: 'Renew', zh: '续订 +', cs: 'text-primary' },

  {
    code: 'Renewal from Billing Retry',
    zh: '从账单重试续订 +',
    cs: 'text-primary',
  },
  { code: 'Refund', zh: '退款 -', cs: 'text-red-500' },
  { code: 'Cancel', zh: '取消' },
  { code: 'Reactivate', zh: '重新激活' },
  { code: 'Reactivate with Crossgrade', zh: '跨级重新激活' },
  { code: 'Grace Period from Introductory Offer', zh: '从介绍优惠进入宽限期' },

  { code: 'Renewals from Grace Period', zh: '从宽限期续订' },
  {
    code: 'Billing Retry from Introductory Offer',
    zh: '从介绍优惠进行账单重试',
  },
  { code: 'Billing Retry from Grace Period', zh: '从宽限期进行账单重试' },
  { code: 'Canceled from Billing Retry', zh: '从账单重试取消' },
  { code: 'Crossgrade from Introductory Offer', zh: '从介绍优惠跨级' },
  { code: 'Grace Period from Paid Subscription', zh: '从付费订阅进入宽限期' },
]

const WhatHappenAfterSubAt = () => {
  const skuId = useSkuId((state) => state.value)
  const updateSkuId = useSkuId((state) => state.update)
  const country = useCountry((state) => state.value)
  const updateCountry = useCountry((state) => state.update)

  const { startDate, endDate } = useDatePicker()

  const {
    data: events,
    mutate,
    isValidating,
  } = useSWR([
    '/apple/events2',
    { pid: 12, country, startDate, endDate, skuId },
  ])

  const groupedEvents = React.useMemo(() => {
    if (!events) return {}
    return events.reduce((acc, event) => {
      if (!acc[event.event]) {
        acc[event.event] = { total: 0, iPhone: 0, iPad: 0 }
      }
      acc[event.event].total += event.total
      acc[event.event].iPhone += event.iPhone || 0
      acc[event.event].iPad += event.iPad || 0
      return acc
    }, {})
  }, [events])

  return (
    <>
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
        <DropdownCountry1 value={country} update={updateCountry} />
        <MyDatePicker />
        <SkuDropdown value={skuId} update={updateSkuId} />
        <Button onClick={() => mutate()} disabled={isValidating}>
          <UpdateIcon
            className={cn('w-4 h-4 mr-2', isValidating && 'animate-spin')}
          />
          Refresh
          {isValidating && 'ing...'}
        </Button>
      </div>
      <Table className="w-auto">
        <TableHeader>
          <TableRow>
            <TableHead>Event</TableHead>
            <TableHead>Event Name</TableHead>
            <TableHead>Total</TableHead>
            <TableHead>iPhone</TableHead>
            <TableHead>iPad</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {eventOrder.map((eventName) => {
            const event = groupedEvents[eventName.code] || {
              total: 0,
              iPhone: 0,
              iPad: 0,
            }
            return (
              <TableRow key={eventName.code}>
                <TableCell className="font-medium">{eventName.code}</TableCell>
                <TableCell className={cn('font-medium', eventName.cs)}>
                  {eventName.zh}
                </TableCell>
                <TableCell>{event.total}</TableCell>
                <TableCell>{event.iPhone}</TableCell>
                <TableCell>{event.iPad}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default WhatHappenAfterSubAt

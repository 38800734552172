import { formatUSD, formatPercent } from '@/lib/format-utils'
import { useRoasStore } from '@/store/roas'
import { getColorByValue } from './color-rules'
import { getWeek } from '@/lib/date-utils'

const MoneyCell = ({ value }) => {
  if (!value) {
    return (
      <div className="text-right">
        <span className="text-muted-foreground">-</span>
      </div>
    )
  }
  return <div className="text-right">{formatUSD(value)}</div>
}

const ProfitCell = ({ revenue, cost }) => {
  if (!revenue || !cost) {
    return (
      <div className="text-right">
        <span className="text-muted-foreground">-</span>
      </div>
    )
  }
  const profit = revenue - cost
  const colorClass = profit >= 0 ? 'text-green-600' : 'text-red-600'
  return <div className={`text-right ${colorClass}`}>{formatUSD(profit)}</div>
}

const getRoasColor = (value) => {
  if (!value) return ''
  const percentage = value / 100
  if (percentage >= 0.8) return 'text-green-600'
  if (percentage <= 0.4) return 'text-red-600'
  return ''
}

const RoasCell = ({ value }) => {
  if (!value) {
    return (
      <div className="text-right">
        <span className="text-muted-foreground">-</span>
      </div>
    )
  }
  const colorClass = getRoasColor(value)
  return (
    <div className={`text-right ${colorClass}`}>
      {formatPercent(value / 100, 2, 2)}
    </div>
  )
}

const DayCell = ({ dayIndex, row }) => {
  const { displayMode, dayMode } = useRoasStore()
  const showPercentage = displayMode === 'percentage'
  const showCumulative = dayMode === 'cumulative'

  const days = row.original.days || {}
  const cost = row.original.cost

  const install_date = row.original.install_date
  if (install_date === '2025-01-03') {
    // console.log(days)
  }
  let value = 0
  if (showCumulative) {
    if (!days[`day${dayIndex}`]) {
      return
    }

    Object.keys(days).forEach((key, index) => {
      if (index > dayIndex) {
        return null
      }

      const dayValue = days[key]
      if (dayValue) {
        value += dayValue
      } else {
        value += 0
      }
    })
  } else {
    value = days[`day${dayIndex}`] || 0
  }

  if (!value) {
    return null
  }

  const colorClass = getColorByValue(value, cost, dayIndex)

  if (showPercentage) {
    if (!cost) {
      return (
        <div className="text-right">
          <span className="text-muted-foreground">-</span>
        </div>
      )
    }

    return (
      <div className={`text-right ${colorClass}`}>
        {formatPercent(value / cost, 1, 1)}
      </div>
    )
  }

  if (install_date === '2025-01-03' && dayIndex === 1) {
    console.log('render', value)
  }

  return <div className={`text-right ${colorClass}`}>{formatUSD(value)}</div>
}

const InstallDateColumn = {
  accessorKey: 'install_date',
  header: ({ column }) => (
    <button
      className="flex items-center gap-1 truncate"
      onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    >
      Install Date
      {column.getIsSorted() === 'asc'
        ? ' ↑'
        : column.getIsSorted() === 'desc'
        ? ' ↓'
        : ''}
    </button>
  ),
  cell: ({ row }) => {
    const [, weekDay] = getWeek(row.original.install_date)
    return (
      <div className="flex flex-col">
        {/* {JSON.stringify(row.original)} */}
        <div>{row.original.install_date}</div>
        <div className="text-xs text-muted-foreground">星期{weekDay}</div>
      </div>
    )
  },
  sortingFn: 'datetime',
  enableSorting: true,
}

const getColumns = (maxDay = 7) => {
  return [
    InstallDateColumn,
    {
      accessorKey: 'revenue',
      header: () => <div className="text-right">Revenue</div>,
      cell: ({ row }) => <MoneyCell value={row.original.revenue} />,
    },
    {
      accessorKey: 'cost',
      header: () => <div className="text-right">Cost</div>,
      cell: ({ row }) => <MoneyCell value={row.original.cost} />,
    },
    {
      id: 'profit',
      header: () => <div className="text-right">Profit</div>,
      cell: ({ row }) => (
        <ProfitCell revenue={row.original.revenue} cost={row.original.cost} />
      ),
    },
    {
      accessorKey: 'roas',
      header: () => <div className="text-right">ROAS</div>,
      cell: ({ row }) => <RoasCell value={row.original.roas} />,
    },
    {
      id: 'spacer',
      header: () => <div className="w-2"></div>,
      cell: () => <div className="w-2"></div>,
    },
    ...Array.from({ length: maxDay + 1 }, (_, i) => ({
      id: `day${i}`,
      cell: ({ row }) => <DayCell dayIndex={i} row={row} />,
      header: () => <div className="text-right">Day {i}</div>,
    })),
  ]
}

export { getColumns }

import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import SetComponent from './SetComponent'

const UPDATE_KEY = 'prReadable'

const SetReadable = () => {
  const { id } = useParams('')
  const { data: user } = useSWR([`/users/${id}/full`])

  return <SetComponent user={user} updateKey={UPDATE_KEY} />
}

export default SetReadable
